const mobileMenu = document.querySelector('.hamburger-menu')
const mobileMenuIcon = document.querySelector('.hamburger')
const menu = document.querySelector('.nav')
const body = document.querySelector('.body')
const logo = document.querySelector('.synrise-logo')
let isMenuOpen = false;

mobileMenuIcon.addEventListener('click', function (e) {
  if (isMenuOpen) {
    mobileMenu.classList.remove('expanded')
    mobileMenuIcon.classList.remove('expanded')
    menu.classList.remove('fixed')
    body.classList.remove('disable')
    logo.classList.remove('white')
    isMenuOpen = false
  } else {
    mobileMenu.classList.add('expanded')
    mobileMenuIcon.classList.add('expanded')
    menu.classList.add('fixed')
    body.classList.add('disable')
    logo.classList.add('white')
    isMenuOpen = true
  }
})


const pills = document.querySelectorAll('.pill')
const cards = document.querySelectorAll('.project-card')
const pillSelected = document.querySelector('.selected')

for (let i = 0; i < pills.length; i++) {
  pills[i].addEventListener('click', function () {
    pillClicked(pills[i])
  })
  pills[i].addEventListener('click', function (e) {
    for (let a = 0; a < pills.length; a++) {
      pills[a].classList.remove('selected')
    }
    pills[i].classList.add('selected')
  })
}
let allprojects;
allprojects = document.querySelectorAll(".project");

function pillClicked(c) {
  c = event.target.id.substring(4)
  let x, i;
  x = document.getElementsByClassName("project");
  if (c == "all") c = "";
  for (i = 0; i < x.length; i++) {
    RemoveClass(x[i], "show");
    if (x[i].className.indexOf(c) > -1) AddClass(x[i], "show");
  }

  function AddClass(element, name) {
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {
        element.className += " " + arr2[i];
      }
    }
  }

  function RemoveClass(element, name) {
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  }
}